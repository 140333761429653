import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import CloseIcon from '@/assets/icons/tsx/Close';
import ArrowNextIcon from '@/assets/icons/tsx/NextArrow';
import vintageIcon from '@/assets/icons/vintage.svg';
import { COOKIE_NAME, useAppClient } from '@/core';
import SvgIcon from 'components/SvgIcon';

import useCookie from '../../../../hooks/useCookie';

import style from './DemonstrationBanner.module.css';


const DemonstrationBanner = ({ mode }: { mode?: 'header' }) => {
  const { host } = useAppClient();
  const [isDemonstrationBlink, setIsDemonstrationBlink] = useCookie(COOKIE_NAME.FEATURE_DEMONSTRATION_BLINK, '');

  const handleDemoClick = () => {
    if (!isDemonstrationBlink) {
      setIsDemonstrationBlink('1', { expires: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000), domain: host });
    }
  };

  return !isDemonstrationBlink && (
    <div className={classNames(style.root, { [style.headerMode]: mode === 'header' })}>
      <span />

      <Link href='/demonstration/' className={classNames(style.title)} onClick={handleDemoClick}>
        <SvgIcon className={style.icon} svg={vintageIcon} />
        <span>Узнайте, как демонстрация ковров у вас дома поможет сделать безошибочный выбор</span>
        <ArrowNextIcon className={style.nextIcon} />
      </Link>

      <button className={style.closeButton} onClick={handleDemoClick}>
        <CloseIcon />
      </button>
    </div>
  );
}

export default DemonstrationBanner;
